'use strict';

import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.ru';

document.querySelectorAll('.phoneinput').forEach(field => {
  let cleave = new Cleave(field, {
    phone: true,
    phoneRegionCode: 'RU',
  });
});