'use strict';

const menuBtn = document.querySelector('.menu_btn'),
      menuIcon = document.querySelector('.menu_btn span'),
      navMenu = document.querySelector('.nav_menu'),
      navLi = document.querySelector('.nav_menu li'),
      navLinks = document.querySelectorAll('.nav_menu a');


menuBtn.addEventListener('click', e => {
  e.preventDefault();
  menuBtn.classList.toggle('menu_btn_active');
  navMenu.classList.toggle('nav_menu_open');
});

navLinks.forEach(element => {
  element.addEventListener('click', ()=>{
    navMenu.classList.remove('nav_menu_open');
    menuBtn.classList.remove('menu_btn_active');
  });
});

document.addEventListener('click', (elem) => {
  if ((elem.target != menuBtn && elem.target != menuIcon && elem.target != navMenu && elem.target != navLi) && (menuBtn.classList.contains('menu_btn_active'))) {
    navMenu.classList.remove('nav_menu_open');
    menuBtn.classList.remove('menu_btn_active');
  }
});





