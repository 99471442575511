'use strict';

const inputCheckbox = document.getElementById('checkbox'),
      divCheckbox = document.querySelector('.checkbox'),
      checkboxField = document.querySelector('.checkbox-field');


checkboxField.addEventListener('click', ()=>{
  if (inputCheckbox.checked) {
    divCheckbox.classList.add('checkbox__checked');
  } else {
    divCheckbox.classList.remove('checkbox__checked');
  }
});
