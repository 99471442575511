'use strict';

const recaptchaSiteKey = '', // ключ сайта для Recaptcha
  selector = 'form',
  formIDs = [];

let curID = '',
  hide = 0, // 1 - прятать форму после отправки (0 - не прятать);
  delay = 3000, // задержка исчезновения сообщения в миллисекундах (0 - не скрывать)
  successMsg = "Ваше сообщение отправлено!", // сообщение об успешной отправке
  successOriginal = false,
  errorMsg = "Ошибка отправки! Попробуйте позже.", // сообщение об ошибке
  waitMsg = 'Идет отправка...', // сообщение об отправке (оставить пустым чтоб не показывать)
  redirect = '', // страница, на котороую перейти после отправки (оставить пустым чтоб никуда не переходить)
  // action = document.querySelector('[src$=mail\\.js]').getAttribute('src').replace('js', 'php'), // путь к скрипту отправки почты
  action = './mail.php', // путь к скрипту отправки почты
  replayClass = '',
  replayMsg = '';

// полифил для forEach для ie11
if ('NodeList' in window && !NodeList.prototype.forEach) {
  console.info('polyfill for IE11');
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

document.querySelectorAll(selector).forEach(indx => {
  if (indx.getAttribute('action') === undefined || indx.getAttribute('action') === null) {
    indx.setAttribute('action', '/');
    indx.setAttribute('method', 'post');
    curID = indx.getAttribute('id');
    if (curID === undefined || curID === null) {
      curID = 'form_id_' + formIDs.length;
      indx.setAttribute('id', curID);
    } else if (formIDs.indexOf(curID) !== -1) {
      curID = curID + '_' + formIDs.length;
      indx.setAttribute('id', curID);
    }
    formIDs.push(curID);
    if (indx.querySelector('.g-recaptcha')) {
      indx.querySelector('.g-recaptcha').setAttribute('data-sitekey', recaptchaSiteKey);
    }
  }
});


ready(function () {
  document.querySelectorAll(selector + '[action = "/"]').forEach(formSubmit => {
    curID = '#' + formSubmit.getAttribute('id');

let elemCurID = document.querySelector(curID);
      if (elemCurID.getAttribute('data-hide') !== undefined && elemCurID.getAttribute('data-hide') !== null) {
          hide = parseInt(elemCurID.getAttribute('data-hide'));
        }
      if (elemCurID.getAttribute('data-delay') !== undefined && elemCurID.getAttribute('data-delay') !== null) {
        delay = parseInt(elemCurID.getAttribute('data-delay'));
        }
let parantForm = elemCurID.parentElement,
    curSuccess = parantForm.querySelector('.w-form-done').textContent.trim(),
    curError = parantForm.querySelector('.w-form-fail').textContent.trim();
      if (curSuccess === 'Thank you! Your submission has been received!') {
        curSuccess = successMsg;
        successOriginal = false;
      }
      if (curError === 'Oops! Something went wrong while submitting the form.') {
        curError = errorMsg;
      }
let curWait = elemCurID.querySelector('[data-wait]').getAttribute('data-wait');
      if (curWait !== 'Please wait...') {
        waitMsg = curWait;
      }
let curRedirect = elemCurID.getAttribute('data-redirect');
      if (curRedirect !== undefined && curRedirect !== null) {
        redirect = curRedirect;
      }
let curAction = elemCurID.getAttribute('action');
      if (curAction !== '/') {
        action = curAction;
      }
      if (elemCurID.getAttribute('data-send') !== undefined && elemCurID.getAttribute('data-send') !== null) {
        elemCurID.insertAdjacentHTML('afterbegin', '<input type="hidden" name="sendto" value="' + elemCurID.getAttribute('data-send') + '">');
      }
      elemCurID.insertAdjacentHTML('afterbegin', '<input type="hidden" name="required_fields">');
      elemCurID.insertAdjacentHTML('afterbegin', '<input type="hidden" name="Страница" value="' + document.location.href + '">');
      elemCurID.insertAdjacentHTML('afterbegin', '<input type="hidden" name="Форма" value="' + elemCurID.getAttribute('data-name') + '">');
let requiredFields = '';
      elemCurID.querySelectorAll('[required]').forEach(el => {
        requiredFields = requiredFields + ', ' + el.getAttribute('name');
      });
      requiredFields = requiredFields.slice(2);
      elemCurID.querySelector('[name="required_fields"]').setAttribute('value', requiredFields);

function sendMailToPhp (event) {
      event.preventDefault();
      let formData = new FormData(elemCurID);
      let submitDiv = elemCurID.querySelector('[type = submit]'),
          submitTxt = submitDiv.getAttribute('value');
        if (waitMsg !== '') {
          submitDiv.setAttribute('value', waitMsg);
        }

      fetch(action, {
          method: 'POST',
          body: formData,
        })
        .then(response => {
          if (!response.ok) {
            return Promise.reject(new Error(response.statusText));
          }
          return Promise.resolve(response);
        })
        .then(response => response.text())
        .then(data => {
          const tempdiv = document.createElement('div');
            tempdiv.insertAdjacentHTML('afterbegin', data);
          const responseMessege = tempdiv.textContent.trim();
          if (responseMessege == 'success') {
            if (redirect !== '' && redirect !== '/-') {
              document.location.href = redirect;
              return (true);
            }
            parantForm.querySelector('.w-form-fail').style.display = 'none';
            replayClass = '.w-form-done';
            replayMsg = curSuccess;
            let replayDiv = parantForm.querySelector(replayClass);
            if (!successOriginal) {
              replayDiv.querySelector('div').textContent = replayMsg;
            }
            replayDiv.style.display = 'block';
            replayDiv.style.opacity = '1';
            if (hide) {
              elemCurID.style.display = 'none';
            }
            submitDiv.setAttribute('value', submitTxt);
            if (delay !== 0) {
              setTimeout(fade, delay, replayDiv);
            }
          } else {
            parantForm.querySelector('.w-form-done').style.display = 'none';
            if (responseMessege === 'SMTP Error: Could not authenticate.') {
              replayMsg = 'Ошибка SMTP: не удалось подтвердить подлинность';
            } else if (responseMessege === 'Invalid address: null') {
              replayMsg = 'Неверный адрес: неуказан!';
            } else if (responseMessege === 'ERROR_REQUIRED') {
              replayMsg = 'Не заполнено обязательное поле!';
            } else if (responseMessege === 'ERROR_RECAPTCHA') {
              replayMsg = 'Подтвердите, что вы не робот!';
            } else {
              replayMsg = curError;
            }
            replayClass = '.w-form-fail';
            let replayDiv = parantForm.querySelector(replayClass);
            if (!successOriginal) {
              replayDiv.querySelector('div').textContent = replayMsg;
            }
            replayDiv.style.display = 'block';
            replayDiv.style.opacity = '1';
            if (hide) {
              elemCurID.style.display = 'none';
            }
            submitDiv.setAttribute('value', submitTxt);
            if (delay !== 0) {
              setTimeout(fade, delay, replayDiv);
            }
          }
        })
        .then(() => {
          if (document.querySelectorAll('div[for^=file]')) {
            document.querySelectorAll('div[for^=file]').forEach(div => {
              const childiv = div.firstChild;
              let b = new Promise((res, rej) => {
                if (childiv) {
                  setTimeout(() => {
                    res(() => {
                      fade(childiv);
                    });
                  }, delay);
                }
              });
              b.then(() => {
                childiv.remove();
              });
            });
          }
          elemCurID.reset();
        })
        .catch(error => {
          parantForm.querySelector('.w-form-done').style.display = 'none';
          if (error.message === 'Not Found') {
            replayMsg = 'Ошибка! Обработчик почты не найден!';
          }
          replayClass = '.w-form-fail';
          let replayDiv = parantForm.querySelector(replayClass);
          if (!successOriginal) {
            replayDiv.querySelector('div').textContent = replayMsg;
          }
          replayDiv.style.display = 'block';
          replayDiv.style.opacity = '1';
          if (hide) {
            elemCurID.style.display = 'none';
          }
          submitDiv.setAttribute('value', submitTxt);
          if (delay !== 0) {
            setTimeout(fade, delay, replayDiv);
          }
        });
}

formSubmit.addEventListener('submit', sendMailToPhp);

document.querySelectorAll('textarea').forEach(element => {
  element.setAttribute('value', '');
});

  });
});

document.querySelectorAll('label[for^=file]').forEach(lab => {
  let fileID = lab.getAttribute('for');
  lab.insertAdjacentHTML('afterend', '<input name="file[]" type="file" id="' + fileID + '" multiple style="display:none;">');
  lab.insertAdjacentHTML('afterend', '<div for="' + fileID + '" class="file-inserted-text"></div>');
  lab.nextElementSibling.style.display = 'none';

  let preview = document.querySelector('div[for="' + fileID + '"]');
  let input = document.getElementById(fileID);
  let fileTypes = [
    'image/jpeg',
    'image/pjpeg',
    'image/png'
  ];

  function updateImageDisplay() {
    while (preview.firstChild) {
      preview.removeChild(preview.firstChild);
    }
    let curFiles = input.files;
    lab.nextElementSibling.style.display = 'block';
    if (curFiles.length === 0) {
      let para = document.createElement('p');
      para.textContent = 'Файлы не выбраны';
      preview.appendChild(para);
    } else {
      let list = document.createElement('ol');
      preview.appendChild(list);
      for (let i = 0; i < curFiles.length; i++) {
        let listItem = document.createElement('li');
        let para = document.createElement('p');
        para.style.cssText = `
          padding:0px;
          margin:3px;
          word-wrap: break-word;
          `;
        if (validFileType(curFiles[i])) {
          para.textContent = curFiles[i].name + '.';
          listItem.appendChild(para);
        } else {
          para.textContent = curFiles[i].name + ': Недопустимый тип файла. Обновите свой выбор.';
          listItem.appendChild(para);
        }
        list.appendChild(listItem);
      }
    }
  }

  function validFileType(file) {
    for (var i = 0; i < fileTypes.length; i++) {
      if (file.type === fileTypes[i]) {
        return true;
      }
    }
    return false;
  }

  document.querySelector('input#' + fileID).addEventListener('change', updateImageDisplay);

});


document.querySelectorAll('.w-form [data-name]').forEach(indx => {
  indx.setAttribute('name', indx.getAttribute('data-name'));
});

document.querySelectorAll('input[type="tel"]').forEach(el => {
  el.classList.add('phoneinput');
});


function ready(callback) {
  // in case the document is already rendered
  if (document.readyState != 'loading') {
    callback();
  }
  // modern browsers
  else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  }
  // IE <= 8
  else {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState == 'complete') {
        callback();
      }
    });
  }
}

function fade(elem, d, f) {
  let fps = f || 50, // кадров в секунду (по умолчанию 50)
    time = d || 800, // время работы анимации (по умолчанию 800мс)
    steps = time / (1000 / fps), // сколько всего покажем кадров 
    op = 1, // текущее значение opacity - изначально 0
    d0 = op / steps; // изменение прозрачности за 1 кадр

  // устанавливаем интервал (1000 / fps) 
  // например, 50fps -> 1000 / 50 = 20мс  
  let timer = setInterval(function () {
    op -= d0; // уменьшаем текущее значение opacity
    elem.style.opacity = op; // устанавливаем opacity элементу DOM
    steps--; // уменьшаем количество оставшихся шагов анимации

    // если анимация окончена
    if (steps <= 0) {
      // убираем интервал выполнения
      clearInterval(timer);
      // и убираем элемент из потока документа
      elem.style.display = 'none';
    }
  }, (1000 / fps));
}
